import { Link } from 'gatsby'
import { StaticImage, getImage } from 'gatsby-plugin-image'

import MainLayout from '~/components/layouts/MainLayout'
import ContactFormSection from '~/components/shared/ContactFormSection'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import { CommonImageProps } from '~/types/common-props'
import { fileToImageLikeData } from '~/utils'
import CaseStudyBackground from '~/views/CaseStudies/components/CaseStudyBackground'
import CaseStudyCta from '~/views/CaseStudies/components/CaseStudyCta'
import CaseStudyHead from '~/views/CaseStudies/components/CaseStudyHead'
import CaseStudyPreview from '~/views/CaseStudies/components/CaseStudyPreview'
import CaseStudyPreviewHead from '~/views/CaseStudies/components/CaseStudyPreviewHead'
import CaseStudyQuote from '~/views/CaseStudies/components/CaseStudyQuote'
import CaseStudySolution from '~/views/CaseStudies/components/CaseStudySolution'
import CaseStudySolutionHead from '~/views/CaseStudies/components/CaseStudySolutionHead'
import CaseStudyTech from '~/views/CaseStudies/components/CaseStudyTech'
import CaseStudyVideoCase from '~/views/CaseStudies/components/CaseStudyVideo'

import * as containerStyles from './Chattic.module.scss'
import useChatticStaticQuery from './useChatticStaticQuery'

const imageProps: CommonImageProps = {
  width: 80,
  height: 80,
}

const quotes = {
  pm: {
    name: 'Arpi',
    position: 'PM Team Lead at Codica',
  },
  designer: {
    name: 'Inessa',
    position: 'UI/UX Lead at Codica',
  },
}

const previewTradeaboatTitle = 'Online Marketplace for Boats'
const previewMalleniTitle = (
  <>
    Custom <br /> E-commerce Solution
  </>
)

const CaseStudiesChattic = () => {
  const query = useChatticStaticQuery()

  const bannerCase = getImage(fileToImageLikeData(query.bannerCase))
  const solutionDeliveredOne = getImage(
    fileToImageLikeData(query.solutionDeliveredOne),
  )
  const solutionDeliveredTwo = getImage(
    fileToImageLikeData(query.solutionDeliveredTwo),
  )
  const solutionDeliveredThree = getImage(
    fileToImageLikeData(query.solutionDeliveredThree),
  )
  const solutionDeliveredFour = getImage(
    fileToImageLikeData(query.solutionDeliveredFour),
  )
  const solutionDeliveredFive = getImage(
    fileToImageLikeData(query.solutionDeliveredFive),
  )
  const solutionDeliveredSix = getImage(
    fileToImageLikeData(query.solutionDeliveredSix),
  )
  const solutionDeliveredSeven = getImage(
    fileToImageLikeData(query.solutionDeliveredSeven),
  )
  const solutionDeliveredEight = getImage(
    fileToImageLikeData(query.solutionDeliveredEight),
  )
  const videoCaseImage = getImage(fileToImageLikeData(query.videoCaseImage))

  return (
    <MainLayout isTransparent scrollTop>
      <CaseStudyHead
        title="Online Marketplace for Selling Collectibles"
        subtitle="Learn how Codica developed a versatile online marketplace for collectibles. It has high performance and reliability. So, you can sell, appraise and insure old pieces safely."
        maxWidthSubtitle="400px"
        maxWidthInfo="410px"
        image={bannerCase}
        classNameImageWrapper={containerStyles.caseStudyHead__imageWrapper}
        imageAlt="Collectible marketplace"
        imageTitle="Marketplace for selling collectibles"
        domain="Collectibles"
        location="United States"
        timeline="November 2021 - May 2022"
        services={
          <>
            <Link to="/services/product-discovery/" key="productKey">
              Product Discovery
            </Link>
            , Front-end, Back-end,
            <Link
              to="/services/online-marketplace-development/"
              className="ml5"
              key="marketplaceKey"
            >
              Online marketplace development
            </Link>
            ,
            <Link to="/services/ui-ux-design/" className="ml5" key="designKey">
              UX/UI design
            </Link>
          </>
        }
        team="2 React Developers, 1 Ruby on Rails Developer, 1 UX/UI Designer, 1 QA Engineer, 1 DevOps Engineer, 1 Project Manager"
        technologies="React, NextJS, Ruby on Rails, Stripe, Sendgrid, Sentry, Shippo, AWS"
        isQuote
      />

      <CaseStudyQuote
        quote={[
          {
            text: '“Regarding the versatility of the marketplace, we thought out the design flow that allows for easy navigation. The UX is adapted to constantly uploading photos, sharing the pieces, and commenting on listings. When designing primary and secondary pages of the marketplace, we wanted to make them attractive and accessible.',
          },
          {
            text: 'In collaboration with the Chattic owner, we chose the brand style and palette. We also selected patterns. They are geometrical drawings on the pages that suit the platform style. Patterns bring aesthetics and a cozy look, making the design more appealing to users.”',
          },
        ]}
        photo={
          <StaticImage
            src="../../../../assets/images/avatars/Inessa.png"
            alt={`${quotes.designer.name}, ${quotes.designer.position} at Codica`}
            title={`${quotes.designer.name}, ${quotes.designer.position}`}
            {...imageProps}
          />
        }
        bgRing={containerStyles.caseStudyQuote__bgRing}
        name={quotes.designer.name}
        position={quotes.designer.position}
      />

      <CaseStudyBackground
        backgroundColor={containerStyles.caseStudyBackground__bgColor}
        lineColor={containerStyles.caseStudyBackground__lineColor}
        bgTextLeft={[
          'Chattic is a platform for collectibles and antiques, ranging from simple items to precious and rare pieces. From non-collectors to ardent professionals, people can share their pieces or look for them on the platform. The marketplace simplifies finding antique pieces and memorabilia, helping bring new life to old items or use antiques in designs. Also, the marketplace is convenient for sharing advice and discussions on pieces.',
          <div className="mb-3" key="bgTextLeft" />,
          'The platform has features that help connect collectible sellers and buyers online. For example, you can get an appraisal of your piece.',
        ]}
        bgTextRight={[
          'With this function, the website allows you to get an expert evaluation of the item’s price. Also, the platform allows you to insure collectibles by simply filling out the form.',
          <div className="mb-3" key="bgTextRight" />,
          'Building from scratch, our team understood the importance of creating a platform to handle the set tasks. We needed to implement a range of core features that distinguish the platform. They include a search for listings and users and a checkout process, including payment and delivery. Regarding the platform’s scale, we also needed to ensure its stable and secure work.',
        ]}
        sectionBusiness={[
          {
            label:
              'Build the marketplace with continuous work despite the number and complexity of operations. The platform should handle thousands of items with photos and other supporting files. It should help hundreds of vendors to sell products and communicate with buyers.',
          },
          {
            label:
              'Integrate the appraisal service that helps vendors assess their items. The integration should allow a vendor to check their piece and receive the relevant appraisal certificate.',
          },
          {
            label:
              'Add redirection to Collectibles Insurance Services in the marketplace. The insurance service should be reliable and secure. The vendor should know that their insurance certificate is from the CIS. The service also should include a reliable payment process.',
          },
          {
            label:
              'Create the bulk upload for many listings from the same seller. The feature should allow gathering several listings in a bundle and publishing them on the platform.',
          },
          {
            label:
              'Implement a shipping aggregator. It helps sellers create shipments and pay their costs online.',
          },
        ]}
        sectionValueDelivered={[
          {
            label:
              'Developed a marketplace for collectibles from scratch. Many transactions can be conducted via the marketplace, such as ordering items, appraisals, and insurance.',
          },
          {
            label:
              'Integrated the appraisal service for assessing items. With it, users can appraise their pieces by filling out a simple form on the platform. You can give additional information on the collectible condition, size, and unique marks.',
          },
          {
            label:
              'Added redirection to the Collectibles Insurance Service allowing to get financial protection for precious items. It is well-grounded and helps receive a valid insurance certificate.',
          },
          {
            label:
              'Integrated Stripe, a simple payment system that allows the processing of complex transactions. We connected the escrow payments, the ability to buy several items from one vendor, and the item return mechanism in case of rejection.',
          },
          {
            label:
              'Implemented the bulk upload of several listings from one seller. It allows listing more than one item in one click. Built a shipping aggregator allowing sellers to compose orders and deliver pieces via an online form on the platform. The platform allows customizing a menu when listing and searching items with filters.',
          },
          {
            label:
              'Created a vivid UI/UX design that conveys brand identity and makes the platform appealing and convenient for users. We helped to create the platform’s visual style and ensured the platform’s scaling.',
          },
        ]}
      />

      <div className={containerStyles.caseStudyQuote__bgLight}>
        <CaseStudyQuote
          quote={[
            {
              text: '“Our work on the Chattic marketplace was exciting and challenging. We included features that help collectible owners appraise and insure their pieces. Also, users can securely pay and ship their items. Despite the multifaceted functionality, the platform’s work is stable. So, users can enjoy a smooth flow in their dealings with collectibles.',
            },
            {
              text: 'What inspired me most is that people can view and share their pieces not for sale, they can follow and comment on the items. So, this platform is more than a marketplace. It is a place for growing a community of people with common interests.”',
            },
          ]}
          photo={
            <StaticImage
              src="../../../../assets/images/avatars/PM-Arpi.png"
              alt={`${quotes.pm.name}, ${quotes.pm.position} at Codica`}
              title={`${quotes.pm.name}, ${quotes.pm.position}`}
              {...imageProps}
            />
          }
          bgRing={containerStyles.caseStudyQuote__bgRing}
          name={quotes.pm.name}
          position={quotes.pm.position}
        />
      </div>

      <CaseStudyCta
        backgroundColor={containerStyles.caseStudyCta__bgColor}
        title="Do you need a reliable team to build a custom marketplace?"
        description="We apply best practices to help you save costs and launch quickly."
        nameButton="Contact us"
        linkButton="/contacts/"
      />

      <div className={containerStyles.caseStudy__bgLight}>
        <CaseStudySolutionHead title="Solution delivered" />
        <CaseStudySolution
          image={solutionDeliveredOne}
          title="Convenient UI/UX design"
          description={[
            'Our team developed the brand style for this platform. We considered the intricacies from UX elements, palette, and typography to unique decorative patterns. The style highlights the platform’s versatility and reliability.',
            <div className="mb-2" key="descrOne" />,
            'As the marketplace is intended to offer collectibles, it was our primary task to outline them from the best side. We brought up the images to focus buyers’ attention on the best aspects of items.',
            <div className="mb-2" key="descrTwo" />,
            'Thus, a buyer can easily find exciting pieces and view their peculiarities. The intuitive and responsive design lets users understand how to list, share, appraise, and insure their collectibles.',
          ]}
          alt="Collectible platform UI/UX design"
          imageTitle="Collectible platform intuitive UI/UX design"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredTwo}
          title="Collectible listings"
          description="Users can list the items they want to sell or share with the community. The feature lets users view, buy, share, and like the piece. A seller can easily edit the listing if necessary. Sellers can list their items in bulk if they offer several listings. Also, a buyer can create a bundle and buy listings from the same seller."
          alt="Collectible listings"
          imageTitle="Collectible and memorabilia listings"
        />
        <CaseStudySolution
          image={solutionDeliveredThree}
          title="Appraisal service"
          description="The feature allows you to get appraisals through the Chattic platform. You can fill out a simple form, giving information on the item size, condition, and remarkable details. After the form has been processed via the platform, a user gets a valid certificate with the appraisal results."
          alt="Collectible appraisal service"
          imageTitle="Collectible and memorabilia appraisal service"
        />
        <CaseStudySolution
          isReverse
          image={solutionDeliveredFour}
          title="Collectibles Insurance Service"
          description={[
            'With this function, collectible owners can insure their items. After completing the necessary form, the seller sends the data for processing via the insurance service website. Then the seller receives a true document certifying the insurance.',
            <div className="mb-2" key="descrFour" />,
            'The system checks the authenticity of the insurance certificate, so the collectible owner can be sure that the insurance is valid. You can also insure several collectibles at once.',
          ]}
          alt="Collectible insurance service"
          imageTitle="Collectible and memorabilia insurance service"
        />
      </div>

      <div className={containerStyles.caseStudy__bgDark}>
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredFive}
          title="A quick search of users and items"
          description="The search feature allows you to select specific parameters to find pieces and people on the platform. You can apply the necessary filters, and the system will throw you the correct search results."
          alt="Search for users and items"
          imageTitle="Quick search for users and items"
        />
        <CaseStudySolution
          isReverse
          isTextWhite
          image={solutionDeliveredSix}
          title="Built-in shipping aggregator"
          description="A seller can create a shipping order online thanks to the built-in shipping aggregator. The feature allows you to track the shipped item status. Thanks to this integration, sellers can send their items via the platform."
          alt="Collectible marketplace shipping aggregator"
          imageTitle="Collectible marketplace built-in shipping aggregator"
        />
        <CaseStudySolution
          isTextWhite
          image={solutionDeliveredSeven}
          title="Swift and secure checkout process"
          description="As we have implemented the Stripe integration, payments are made quickly and securely. The payment system allows for escrow payment, holding the amount until a buyer picks up an item. Also, a buyer can purchase several pieces from the same seller as a bundle."
          alt="Collectible marketplace checkout process"
          imageTitle="Collectible marketplace secure checkout process"
        />
        <CaseStudySolution
          isReverse
          isImageSmall
          isTextWhite
          image={solutionDeliveredEight}
          title="Follow/message/comment features"
          description="The platform serves as a place where users can discuss their old pieces. You can choose people you want to follow and comment on their listings. This helps build a community interested in collectibles and antiques."
          alt="Collectible marketplace social features"
          imageTitle="Collectible marketplace social sharing features"
        />
      </div>

      <div className={containerStyles.caseStudyVideo}>
        <LazyHydrate whenVisible>
          <CaseStudyVideoCase
            title="How it works"
            description="The platform is convenient for selling, buying, appraising, and insuring collectibles and antiques. Chattic has an intuitive flow for listing and viewing pieces. You can like and share pieces and list them separately or in bulk. Sellers and buyers are covered with secure payment processes. Also, sellers can create online shipments in the shipping aggregator. Below is a short overview of how Chattic works."
            ringsClass={containerStyles.caseStudyVideo__ring}
            image={videoCaseImage}
            videoLink="https://www.youtube.com/embed/n56C1W_IWvo"
          />
        </LazyHydrate>
      </div>

      <div className={containerStyles.caseStudyTechSection}>
        <CaseStudyTech
          technologies={[
            { label: 'React JS', icon: 'react' },
            { label: 'Ruby on Rails', icon: 'ror' },
            { label: 'AWS', icon: 'aws' },
            { label: 'Next.js', icon: 'nextjs' },
          ]}
          integrations={[
            { label: 'Stripe', icon: 'stripe' },
            { label: 'Google Maps API', icon: 'reactGoogleMaps' },
            { label: 'Shippo', icon: 'shippo' },
            { label: 'ValueMyStuff API', icon: 'valueMyStuffApi' },
            { label: 'Sendgrid', icon: 'sendgrid' },
            { label: 'Sentry', icon: 'sentry' },
          ]}
        />
        <div className="d-none d-lg-block">
          <SubtractBlock />
        </div>
      </div>

      <div className={containerStyles.caseContactFormSection}>
        <ContactFormSection title="Let’s turn your idea into a successful product!" />
      </div>

      <div className={containerStyles.caseStudyProjectsSection}>
        <div className="container case-wrapper-xl">
          <div className="row">
            <CaseStudyPreviewHead title="Other projects" />
            <CaseStudyPreview
              title={previewMalleniTitle}
              color="malleniColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/malleni.png"
                  alt={`Case study: ${previewMalleniTitle} | Codica`}
                  title={`Case study: ${previewMalleniTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="custom-multi-vendor-marketplace-platform"
            />
            <CaseStudyPreview
              title={previewTradeaboatTitle}
              color="tradeaboatColor"
              image={
                <StaticImage
                  src="../../../../assets/images/case-study/preview-other-project/tradeaboat.png"
                  alt={`Case study: ${previewTradeaboatTitle} | Codica`}
                  title={`Case study: ${previewTradeaboatTitle}`}
                  placeholder="blurred"
                  width={350}
                  objectFit="fill"
                />
              }
              url="online-marketplace-for-boats"
            />
          </div>
        </div>
      </div>
    </MainLayout>
  )
}

export default CaseStudiesChattic
