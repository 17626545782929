// extracted by mini-css-extract-plugin
export var caseContactFormSection = "by_kw";
export var caseStudyBackground__bgColor = "by_ks";
export var caseStudyBackground__lineColor = "by_kt";
export var caseStudyCta__bgColor = "by_lG";
export var caseStudyHead__imageWrapper = "by_kp";
export var caseStudyProjectsSection = "by_kx";
export var caseStudyQuote__bgLight = "by_kD";
export var caseStudyQuote__bgRing = "by_kr";
export var caseStudyTechSection = "by_kv";
export var caseStudyVideo = "by_kS";
export var caseStudyVideo__ring = "by_kG";
export var caseStudy__bgDark = "by_km";
export var caseStudy__bgLight = "by_kl";